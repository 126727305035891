<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <HelloWorld msg="Welcome to Your Vue.js App"/>
  <!-- <InvitePage /> -->
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
// import InvitePage from './components/InvitePage.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    // InvitePage
  }
}
</script>

<style>
</style>
