<template>  
  <div>
    <div id="black"></div>
    <div id="page1">
      <div id="indexcanvas">
      </div>
      <div id="indexbar"><div id="gopage2" @click="gopage2()">OPENING</div></div>      
		</div>
    <!-- <audio id="bgaudio" autoplay="" preload src="https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/bg.ogg"></audio> -->
    <div id="bg">
      <canvas id="three"></canvas>
      <div id="back1" @click="backpage1()"></div>
      <div id="tip1" >
        <img class="img1" src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/page2_text1.png'>
      </div>
      <div id="tip2" >
        <img class="img2" src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/page2_text2.png'>
      </div>
      <div id="full">
        <div id="back3" @click="backthree()"></div>
        <div id="videocanvas1" class="videocanvas"></div>
        <div id="videocanvas2" class="videocanvas"></div>
        <div id="videocanvas3" class="videocanvas"></div>
        <div id="videocanvas4" class="videocanvas"></div>
        <div id="videocanvas5" class="videocanvas"></div>        
        <div id="control">
          <div class="nav"><button id="gopage5">进入灵感之境</button></div>
          <div class="bar">
            <img src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起1.gif' @click="this.changevideo(1);">
            <img src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起2.gif' @click="this.changevideo(2);">
            <img src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起3.gif' @click="this.changevideo(3);">
            <img src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起4.gif' @click="this.changevideo(4);">
            <img src='https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起5.gif' @click="this.changevideo(5);">
          </div>
        </div>
      </div>      
    </div>
    <div id="page5">
      <div id="back5" @click="backpage2()"></div>
      <button id="gopage6">进入惊喜之境</button>
    </div>
    <div id="page7">
      <div id="back7" @click="backpage5()"></div>
      <div id="left" @click="btnLeft"></div>
      <div id="right" @click="btnRight"></div>
      <!-- <div id="save">长按保存</div> -->
      <img id="output" :src="image" class="output">
      <div id="sharetip"></div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Reflector } from '../../public/Reflector.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import * as TWEEN from 'three/examples/jsm/libs/tween.module.min.js';
import { Projector } from 'three/examples/jsm/renderers/Projector.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import GifLoader from 'three-gif-loader';
import JSMpeg from '@cycjimmy/jsmpeg-player';
// import wx from 'weixin-js-sdk';

let camera, renderer, controls, stats;
let scene;
let cube1, cube2, cube3, cube4, cube5;
let composer, renderPass, outlinePass;
// let _this;
let mouse, raycaster, projector;
let soundbg;
let texture1;
let needRender = false;
let player0;
let player1, player2, player3, player4, player5;
let threeing = false;

export default {
  name: 'HelloWorld',
  components: {
    // VueDrawingCanvas,
  },
  props: {
    msg: String
  },
  data() {
    return {
      yourname: '',      
      image: '',
      pics:['paper1.jpg', 'paper2.jpg', 'paper3.jpg', 'paper4.jpg', 'paper5.jpg'],
      order: 0,
    }
  },
  mounted() {
    // document.getElementById( 'page5' ).style.display = 'block';
    // document.getElementById( 'page7' ).style.display = 'block';

    this.image = 'models/' + this.pics[this.order];
    var output = document.getElementById('output');
    var page7 = document.getElementById('page7');
    setTimeout(function() {      
      var h1 = parseInt(window.getComputedStyle(page7, null).height.toString().replace('px',''));
      var h2 = parseInt(window.getComputedStyle(output, null).height.toString().replace('px',''));
      output.style.marginTop = (h1-h2)/2 + 'px';
    }, 200 );
    
    // var bgaudio = document.getElementById("bgaudio");
    // bgaudio.play();

    this.init(); 
    this.initVideo();

    // function autoPlayVideo(){
    //   wx.config({
    //     debug:false,
    //     appId:"",
    //     timestamp:1,
    //     nonceStr:"",
    //     signature:"",
    //     jsApiList:[]
    //   });
    //   wx.ready(function(){
    //     var bgaudio = document.getElementById("bgaudio");
    //     bgaudio.play();
    //   });

    //   document.addEventListener("WeixinJSBridgeReady", function () {
    //     var bgaudio = document.getElementById("bgaudio");
    //     bgaudio.play();

    //     // let a = new Audio('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/bg.ogg');
    //     // a.load();
    //   }, false);
    // }
    // autoPlayVideo();

    const gopage5 = document.getElementById( 'gopage5' );
    gopage5.addEventListener( 'click', function () {
      player1.stop();
      player2.stop();
      player3.stop();
      player4.stop();
      player5.stop();
      document.getElementById('page5').style.display = 'flex';
      document.getElementById('page5').classList.add('run-nav');
    });

    const gopage6 = document.getElementById( 'gopage6' );
    gopage6.addEventListener( 'click', function () {
      document.getElementById('page7').style.display = 'flex';
      document.getElementById('page7').classList.add('run-nav');
    });

    const sharetip = document.getElementById( 'sharetip' );
    sharetip.addEventListener( 'click', function () {
      sharetip.classList.add('run-slowhiding');
      setTimeout(function() { document.getElementById( 'sharetip' ).remove(); }, 1000);      
    });
  },
  methods: {
    backthree() {
      document.getElementById('full').style.display = 'none';
      document.getElementById( 'videocanvas1' ).style.display = 'none';
      document.getElementById( 'videocanvas2' ).style.display = 'none';
      document.getElementById( 'videocanvas3' ).style.display = 'none';
      document.getElementById( 'videocanvas4' ).style.display = 'none';
      document.getElementById( 'videocanvas5' ).style.display = 'none';
      needRender = true;
      soundbg.play();
      threeing = true;
    },
    backpage5() {
      document.getElementById('page7').style.display = 'none';
    },
    backpage2() {
      document.getElementById('page5').style.display = 'none';
    },
    backpage1() {
      // console.log('backpage1');
      this.initPlayer0();
      soundbg.pause();
      document.getElementById( 'page1' ).style.display = 'block';
      document.getElementById( 'bg' ).style.display = 'none';
    },
    btnLeft() {
      if (this.order-1 < 0) {
        this.order = this.pics.length - 1;
      } else {
        this.order--;
      }
      this.image = 'models/' + this.pics[this.order];
    },
    btnRight() {
      if (this.order+1 > this.pics.length - 1) {
        this.order = 0;
      } else {
        this.order++;
      }
      this.image = 'models/' + this.pics[this.order];
    },
    gopage2() {      
      player0.destroy();
      setTimeout(function() { threeing = true; }, 2000);
      document.getElementById( 'black' ).style.display = 'block';
      document.getElementById( 'black' ).animate({ opacity: 1 }, 1000).onfinish = function() {
        document.getElementById( 'black' ).style.opacity = 1;
        document.getElementById( 'page1' ).style.display = 'none';
        // document.getElementById( 'page1' ).remove();
        document.getElementById( 'bg' ).style.display = 'block';
        needRender = true;
        new TWEEN.TWEEN.Tween(camera.position).to({
          x: 0,
          y: 0,
          z: 2.5,
        }, 2600).easing(TWEEN.TWEEN.Easing.Quintic.Out).onUpdate(function () {
        }).onComplete(function () {
          soundbg.play();
          document.getElementById( 'tip1' ).animate({ opacity: 1 }, 200).onfinish = function() {
            document.getElementById( 'tip1' ).style.opacity = 1;
          };
          document.getElementById( 'tip2' ).animate({ opacity: 1 }, 200).onfinish = function() {
            document.getElementById( 'tip2' ).style.opacity = 1;
          };          
        }).start(); 
        document.getElementById( 'black' ).animate({ opacity: 0 }, 2000).onfinish = function() {
          document.getElementById( 'black' ).style.opacity = 0;
          document.getElementById( 'black' ).style.display = 'none';
          soundbg.play();       
        };
      };      
    },
    initPlayer0() {
      var sub = 'https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/index';
      var src = sub + '.ts';
      player0 = new JSMpeg.VideoElement('#indexcanvas', src, {
        autoplay: false,
        loop: true,
        control: true,
        audio: true,
        poster: sub + '.jpg',
      }, {
        onPlay: function() {
          // 首页自动播放声音，但是对IOS无效
          // soundbg.play();  
          // document.getElementById('bgaudio').play();
          setTimeout(function() {
            document.getElementById('indexbar').classList.add('run-slowshowing');
          }, 5000);
        }
      });
    },
    initVideo() {
      this.initPlayer0();
      
      var sub1 = 'https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起1';
      player1 = new JSMpeg.VideoElement('#videocanvas1', sub1 + '.ts', {
        autoplay: false,
        loop: false,
        control: true,
        audio: true,
        poster: sub1 + '.jpg',
      }, {
        onPlay: function() {
          console.log('play');
          var control = document.getElementById('control');
          if (control.classList.contains('run-flyin')) {
            control.classList.remove('run-flyin');
            control.classList.add('run-flyout');
          }
          document.getElementById( 'back3' ).style.opacity = 0;
        },
        onEnded: function() {
          console.log('stop');
          var control = document.getElementById('control');
          control.classList.remove('run-flyout');
          control.classList.add('run-flyin');
          document.getElementById( 'back3' ).animate({ opacity: 1 }, 200).onfinish = function() {
            document.getElementById( 'back3' ).style.opacity = 1;
          };
        },
      });

      var sub2 = 'https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起2';
      player2 = new JSMpeg.VideoElement('#videocanvas2', sub2 + '.ts', {
        autoplay: false,
        loop: false,
        control: true,
        audio: true,
        poster: sub2 + '.jpg',
        hooks: {
          play: function() {
            console.log('play');
            var control = document.getElementById('control');
            if (control.classList.contains('run-flyin')) {
              control.classList.remove('run-flyin');
              control.classList.add('run-flyout');              
            }
            document.getElementById( 'back3' ).style.opacity = 0;
          },
          stop: function() {
            console.log('stop');
            var control = document.getElementById('control');
            control.classList.remove('run-flyout');
            control.classList.add('run-flyin');
            document.getElementById( 'back3' ).animate({ opacity: 1 }, 200).onfinish = function() {
              document.getElementById( 'back3' ).style.opacity = 1;
            };
          },
        }
      });

      var sub3 = 'https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起3';
      player3 = new JSMpeg.VideoElement('#videocanvas3', sub3 + '.ts', {
        autoplay: false,
        loop: false,
        control: true,
        audio: true,
        poster: sub3 + '.jpg',
        hooks: {
          play: function() {
            console.log('play');
            var control = document.getElementById('control');
            if (control.classList.contains('run-flyin')) {
              control.classList.remove('run-flyin');
              control.classList.add('run-flyout');
            }
            document.getElementById( 'back3' ).style.opacity = 0;
          },
          stop: function() {
            console.log('stop');
            var control = document.getElementById('control');
            control.classList.remove('run-flyout');
            control.classList.add('run-flyin');
            document.getElementById( 'back3' ).animate({ opacity: 1 }, 200).onfinish = function() {
              document.getElementById( 'back3' ).style.opacity = 1;
            };
          },
        }
      });
      
      var sub4 = 'https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起4';
      player4 = new JSMpeg.VideoElement('#videocanvas4', sub4 + '.ts', {
        autoplay: false,
        loop: false,
        control: true,
        audio: true,
        poster: sub4 + '.jpg',
        hooks: {
          play: function() {
            console.log('play');
            var control = document.getElementById('control');
            if (control.classList.contains('run-flyin')) {
              control.classList.remove('run-flyin');
              control.classList.add('run-flyout');
            }
            document.getElementById( 'back3' ).style.opacity = 0;
          },
          stop: function() {
            console.log('stop');
            var control = document.getElementById('control');
            control.classList.remove('run-flyout');
            control.classList.add('run-flyin');
            document.getElementById( 'back3' ).animate({ opacity: 1 }, 200).onfinish = function() {
              document.getElementById( 'back3' ).style.opacity = 1;
            };
          },
        }
      });

      var sub5 = 'https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/左起5';
      player5 = new JSMpeg.VideoElement('#videocanvas5', sub5 + '.ts', {
        autoplay: false,
        loop: false,
        control: true,
        audio: true,
        poster: sub5 + '.jpg',
        hooks: {
          play: function() {
            console.log('play');
            var control = document.getElementById('control');
            if (control.classList.contains('run-flyin')) {
              control.classList.remove('run-flyin');
              control.classList.add('run-flyout');
            }
            document.getElementById( 'back3' ).style.opacity = 0;
          },
          stop: function() {
            console.log('stop');
            var control = document.getElementById('control');
            control.classList.remove('run-flyout');
            control.classList.add('run-flyin');
            document.getElementById( 'back3' ).animate({ opacity: 1 }, 200).onfinish = function() {
              document.getElementById( 'back3' ).style.opacity = 1;
            };
          },
        }
      });
    },
    onDocumentTouchStart(event) {
      // event.preventDefault();
      event.clientX = event.touches[0].clientX;
      event.clientY = event.touches[0].clientY;
      this.onDocumentMouseDown(event);
    },
    onDocumentMouseDown(event) {      
      // this.yourname = 'touch ' + Math.random(1000);
      console.log('touch ' + Math.random(1000));
      if (!threeing) {
        // this.yourname = 'threeing is not' + Math.random(1000);
        console.log('threeing is not ' + Math.random(1000));
        return;
      }
      this.yourname = 'threeing is ' + threeing;
      if (document.getElementById( 'videocanvas1' ).style.display=='block'
      || document.getElementById( 'videocanvas2' ).style.display=='block'
      || document.getElementById( 'videocanvas3' ).style.display=='block'
      || document.getElementById( 'videocanvas4' ).style.display=='block'
      || document.getElementById( 'videocanvas5' ).style.display=='block') {
        // this.yourname = 'videocanvas is block';
        return;
      }
      // this.yourname = 'threeing is true ' + Math.random(1000);
      
      var vector = new THREE.Vector3( ( event.clientX / window.innerWidth ) * 2 - 1, - ( event.clientY / window.innerHeight ) * 2 + 1, 0.5 );
      projector.unprojectVector(vector,camera);
      raycaster = new THREE.Raycaster(camera.position, vector.sub(camera.position).normalize() );

      // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      // mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      // this.yourname = 'x: ' + mouse.x + ' y:' + mouse.y;
      // mouse.x = (event.clientX / renderer.domElement.width) * 2 - 1;
      // mouse.y = -(event.clientY / renderer.domElement.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);
      // this.yourname = intersects.length + ' intersects.length ' + intersects[0].object.name; 
      if (intersects.length>0) {
        // this.yourname = intersects[0].object.name;
        // console.log(intersects);
        if (intersects[0].object.name=='cube1') {
          // this.yourname = 'cube1';
          console.log('cube1 clicked');
          this.showvideo({x: 0, y: 0, z: -0.4,}, 3);
          return;
        } else if (intersects[0].object.name=='cube2') {
          // this.yourname = 'cube2';
          console.log('cube2 clicked');
          this.showvideo({x: -0.6, y: 0, z: -0.2,}, 2);
          return;
        } else if (intersects[0].object.name=='cube3') {
          // this.yourname = 'cube3';
          console.log('cube3 clicked');
          this.showvideo({x: -1, y: 0, z: -0.68,}, 1);
          return;
        } else if (intersects[0].object.name=='cube4') {
          // this.yourname = 'cube4';
          console.log('cube4 clicked');
          this.showvideo({x: 0.6, y: 0, z: -0.2,}, 4);
          return;
        } else if (intersects[0].object.name=='cube5') {
          // this.yourname = 'cube5';
          console.log('cube5 clicked');
          this.showvideo({x: 1, y: 0, z: -0.3,}, 5);
          return;
        }
      }    
    },
    init() {
      mouse = new THREE.Vector2();
      raycaster = new THREE.Raycaster();
      projector = new Projector();
      
      // window.addEventListener('mousedown', this.onDocumentMouseDown, false);
      // window.addEventListener('touchstart', this.onDocumentTouchStart, false);

      stats = new Stats();
			// document.body.appendChild( stats.dom );

      const canvas = document.querySelector('#three');
      
			camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
      camera.position.z = 5;
      
			renderer = new THREE.WebGLRenderer({canvas, antialias: true, alpha: true });
			renderer.setSize( window.innerWidth, window.innerHeight );
      // renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.autoClear = false;
      
      renderer.domElement.addEventListener('mousedown', this.onDocumentMouseDown);

      // 背景音乐 
      const listener = new THREE.AudioListener();
      camera.add( listener );
      soundbg = new THREE.Audio( listener );
      // soundenter = new THREE.Audio( listener );
      const audioLoader = new THREE.AudioLoader();
      audioLoader.load( 'models/bg.ogg', function( buffer ) {
        soundbg.setBuffer( buffer );
        soundbg.setLoop( true );
        soundbg.setVolume( 0.3 );
      });
      // audioLoader.load( 'models/open.ogg', function( buffer ) {
      //   soundente.setBuffer( buffer );
      //   soundenter.setVolume( 0.3 );
      //   soundenter.setLoop( false );
      // });

      // const pmremGenerator = new THREE.PMREMGenerator( renderer );
      
      scene = new THREE.Scene();
      var textureLoader = new THREE.TextureLoader();  
      const bgTexture = textureLoader.load('models/H5-25.jpg');      
      scene.background = bgTexture;

      
      const planeGeometry = new THREE.CircleBufferGeometry(20, 20);
      const mirror = new Reflector(planeGeometry, {
          clipBias: 0.03,
          textureWidth: window.innerWidth * window.devicePixelRatio,
          textureHeight: window.innerHeight * window.devicePixelRatio,
          color: new THREE.Color(0xffffff),
          // recursion: 1,
      });
      mirror.name = 'mirror';
      mirror.position.set( 0, -0.28, 0 );
      mirror.rotation.x = -0.5 * Math.PI;
      scene.add(mirror);

      const geometry = new THREE.BoxGeometry( 0.55, 0.55, 0.003 );

      const loader = new GifLoader();
      texture1 = loader.load('models/左起3.gif');
      // console.log(texture1.name);
      cube1 = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { map: texture1 } ) );
      cube1.name = 'cube1';
      scene.add( cube1 );

      const texture2 = loader.load('models/左起2.gif');
      cube2 = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { map: texture2 } ) );
      cube2.name = 'cube2';
      scene.add( cube2 );
      cube2.position.set(-0.6, 0, -0.2);
      cube2.rotateY(-0.6);

      const texture3 = loader.load('models/左起1.gif');
      cube3 = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { map: texture3 } ) );
      cube3.name = 'cube3';
      scene.add( cube3 );
      cube3.position.set(-1, 0, -0.68);
      cube3.rotateY(-1.1);

      const texture4 = loader.load('models/左起4.gif');
      cube4 = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { map: texture4 } ) );
      cube4.name = 'cube4';
      scene.add( cube4 );
      cube4.position.set(0.6, 0, -0.2);
      cube4.rotateY(0.6);

      const texture5 = loader.load('models/左起5.gif');
      cube5 = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( { map: texture5 } ) );
      cube5.name = 'cube5';
      scene.add( cube5 );
      cube5.position.set(1, 0, -0.68);
      cube5.rotateY(1.1);

      controls = new OrbitControls( camera, renderer.domElement );
			controls.target.set( 0, 0, -1 );
			controls.update();
      controls.enableZoom = true;
			controls.enablePan = false;
			controls.enableDamping = true;
      controls.dampingFactor = 0.02;
      controls.minPolarAngle = Math.PI / 2 - 0.2;
      controls.maxPolarAngle = Math.PI / 2 - 0.2;    

      this.outlineObj([cube1, cube2, cube3, cube4, cube5]);
      this.animate();
    },
    outlineObj (selectedObjects) {
      // 创建一个EffectComposer（效果组合器）对象，然后在该对象上添加后期处理通道。
      composer = new EffectComposer(renderer);
      // 新建一个场景通道  为了覆盖到原理来的场景上
      renderPass = new RenderPass(scene, camera);
      composer.addPass(renderPass);
      // 物体边缘发光通道
      outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera, selectedObjects);
      outlinePass.selectedObjects = selectedObjects;
      outlinePass.edgeStrength = 10.0; // 边框的亮度
      outlinePass.edgeGlow = 1;// 光晕[0,1]
      outlinePass.usePatternTexture = false; // 是否使用父级的材质
      outlinePass.edgeThickness = 2.0; // 边框宽度
      outlinePass.downSampleRatio = 0.5; // 边框弯曲度
      outlinePass.pulsePeriod = 5; // 呼吸闪烁的速度
      outlinePass.visibleEdgeColor.set(parseInt(0xffffff)); // 呼吸显示的颜色
      outlinePass.hiddenEdgeColor = new THREE.Color(0, 0, 0); // 呼吸消失的颜色
      outlinePass.clear = true;
      composer.addPass(outlinePass);
      // 自定义的着色器通道 作为参数
      var effectFXAA = new ShaderPass(FXAAShader);
      effectFXAA.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight);
      effectFXAA.renderToScreen = true;
      composer.addPass(effectFXAA);
    },
    animate() {
      requestAnimationFrame( this.animate );
      // const time = performance.now() * 0.001 + 6000;
      try {
        TWEEN.TWEEN.update();
      } catch (error) {
        /* eslint-disable */
      }
      controls.update();
      if (needRender) {
        stats.update();
        // renderer.clear();
        renderer.render( scene, camera );
        if (composer) {
          composer.render();
        }
      }
    },
    showvideo(pos, src) {
      soundbg.pause();
      needRender = false;
      full.classList.add('run-slowshowing');
      full.style.display = "block";
      full.style.opacity = 1;

      document.getElementById('videocanvas1').style.display = 'none';
      document.getElementById('videocanvas2').style.display = 'none';
      document.getElementById('videocanvas3').style.display = 'none';
      document.getElementById('videocanvas4').style.display = 'none';
      document.getElementById('videocanvas5').style.display = 'none';      
      switch (src) {
        case 1:
          document.getElementById('videocanvas1').style.display = 'block';
          break;
        case 2:
          document.getElementById('videocanvas2').style.display = 'block';
          break;
        case 3:
          document.getElementById('videocanvas3').style.display = 'block';
          break;
        case 4:
          document.getElementById('videocanvas4').style.display = 'block';
          break;
        case 5:
          document.getElementById('videocanvas5').style.display = 'block';
          break;
      }

      // var control = document.getElementById('control');
      // if (control.classList.contains('run-flyin')) {
      //   control.classList.remove('run-flyin');
      // }
      // if (control.classList.contains('run-flyout')) {
      //   control.classList.remove('run-flyout');
      // }
      // control.style.bottom = '-240px';
      // controls.enableDamping = false;
      // controls.enabled = false;      
      
      // new TWEEN.TWEEN.Tween(camera.position).to(pos, 500).easing(TWEEN.TWEEN.Easing.Linear.None).onUpdate(function () {
      //     // camera.lookAt(camera.target);
      // }).onComplete(function () {
      //   // controls.target.set( 0, 0, 0 );
      //   // camera.lookAt( 0,0,0 );
      //   soundbg.pause();
      //   _this.options.src = '';
      //   _this.options.src = src;

      //   var full = document.getElementById('full');
      //   full.classList.add('run-slowshowing');
      //   full.style.display = "block";
      //   full.style.opacity = 1;
      //   playing = true;
      //   needRender = false;
      // }).start();
    },
    changevideo(src) {
      soundbg.pause();
      document.getElementById('videocanvas1').style.display = 'none';
      document.getElementById('videocanvas2').style.display = 'none';
      document.getElementById('videocanvas3').style.display = 'none';
      document.getElementById('videocanvas4').style.display = 'none';
      document.getElementById('videocanvas5').style.display = 'none';      
      switch (src) {
        case 1:
          document.getElementById('videocanvas1').style.display = 'block';
          break;
        case 2:
          document.getElementById('videocanvas2').style.display = 'block';
          break;
        case 3:
          document.getElementById('videocanvas3').style.display = 'block';
          break;
        case 4:
          document.getElementById('videocanvas4').style.display = 'block';
          break;
        case 5:
          document.getElementById('videocanvas5').style.display = 'block';
          break;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#three {
  z-index: 99;
  position: relative;
  /* top: -150px; */
  height: 100%;
}

#tip1 {
  position: absolute;
  z-index: 100;
  left: 0px;
  /* width: calc(100vw); */
  /* height: 200px; */
  top:200px;
  /* top: 0px; */
  color: #ddd;
  opacity: 0;
}

#tip1 .img1 {
  width: calc(100vw);
}

#tip2 {
  position: absolute;
  z-index: 100;
  left: 0px;
  /* width: calc(100vw); */
  /* height: 200px; */
  bottom:100px;
  /* top: 0px; */
  color: #ddd;
  opacity: 0;
}

#tip2 .img2 {
  width: calc(100vw);
}

#bg {
  position: absolute;
  display: none;
}

#full {
	position: absolute;
	font-size: 16px;
	z-index: 153;
	top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: calc(100vw);
  height: calc(100vh);
	display: none;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: black;
  line-height: 100%;
  opacity: 0;
  overflow: hidden;
}

.run-slowshowing {
  animation: slowshowing 0.5s;
  animation-fill-mode:forwards;
  -webkit-animation: slowshowing 0.5s;
  -webkit-animation-fill-mode:forwards;  
}

.run-slowhiding {
  animation: slowhiding 0.5s;
  animation-fill-mode:forwards;
  -webkit-animation: slowhiding 0.5s;
  -webkit-animation-fill-mode:forwards;  
}

.run-flyin {
  animation: flyin 2s;
  animation-fill-mode:forwards;
  -webkit-animation: flyin 2s;
  -webkit-animation-fill-mode:forwards;  
}

.run-flyout {
  animation: flyout 2s;
  animation-fill-mode:forwards;
  -webkit-animation: flyout 2s;
  -webkit-animation-fill-mode:forwards;
}

@keyframes slowshowing {
  0% {
		opacity: 0;
	}
  100% {
    opacity: 1;
  }
}

@keyframes slowhiding {
  0% {
		opacity: 1;
	}
  100% {
    opacity: 0;
  }
}

@keyframes flyin {
  0% {
		bottom: -240px;
	}
  100% {
    bottom: 0px;
  }
}

@keyframes flyout {
  0% {
		bottom: 0px;
	}
  100% {
    bottom: -240px;
  }
}

#control {
  z-index: 160;
  position: absolute;
  width: calc(100vw);
  height: 240px;
  left: 0px;
  right: 0px;
  bottom: -240px;
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
  overflow: hidden;
}

#control .nav {
  margin-top: 15px;
  text-align: center;
}

#control .bar {
  margin-top: 60px;
  text-align: center;
}

#control .bar img {
  width: 60px;
  height: 60px;
  border: solid 1px #ddd;
  margin-left: 4px;
  margin-right: 4px;
  box-shadow: 0px 0px 10px #fff;
  -moz-box-shadow:0px 0px 10px #fff;
  -webkit-box-shadow:0px 0px 10px #fff;  
}

#gopage5 {
  background: transparent;
  border: 0;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 18px;
  text-transform: uppercase;  
  cursor: pointer;
  box-shadow:0px 0px 20px #fff;
	-moz-box-shadow:0px 0px 20px #fff;
	-webkit-box-shadow:0px 0px 20px #fff;  
}

#gopage6 {
  position: absolute;
  left: calc(100vw / 2 - 60px);
  bottom: calc(60px + (50vh - 100vw));
  /* margin-top: 550px; */
  z-index: 201;
  background: transparent;
  border: 0;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 18px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow:0px 0px 20px #fff;
	-moz-box-shadow:0px 0px 20px #fff;
	-webkit-box-shadow:0px 0px 20px #fff;  
}

#page5 {
  overflow: hidden;
	position: absolute;
	font-size: 16px;
	z-index: 200;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: rgba(0,0,0,1);
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/H5-23.jpg');
  background-size: 100%;
  background-position: 0% 40%;
  opacity: 1;
}

#page6 {
  overflow: hidden;
	position: absolute;
	font-size: 16px;
	z-index: 250;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	justify-content: center;
	flex-direction: column;
	background: rgba(0,0,0,1);
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/H5-25.jpg');
  background-size: 100%;
  opacity: 1;
}

#page7 {
  position: absolute;
  overflow: hidden;
	z-index: 300;
	top: 0;
	left: 0;
	width: 100%;
	/* height: 100%; */
  height: calc(100vh);
	display: none;
	/* background: rgba(0,0,0,1); */
  background-color: white;
  opacity: 1;
  /* background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/paper1.jpg');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed; */
}

.output {
  width: calc(100vw);
  /* top: 50%; */
  /* margin-top: 50px; */
  /* margin-top: calc( ( 100vh - 1600px / ( 900px / 100vw ) ) ); */
  /* height: calc(1600px / (900px / 100vw) + 200px); */
  /* margin-top: calc( (100vh - 1600 / (900 / 100vw)) ); */
}

.run-nav {
  animation: nav 1s;
  animation-fill-mode:forwards;
  -webkit-animation: nav 1s;
  -webkit-animation-fill-mode:forwards;
}

@keyframes nav {
  0% {
		opacity: 0;
	}
  100% {
    opacity: 1;
  }
}

#page1 {
  overflow: hidden;
  width: calc(100vw);
  height: calc(100vh);
}

.videocanvas {
  width: calc(100vw);
  height: calc(100vh);
  display: block;
  text-align: center;
  overflow: hidden;
}

#indexcanvas {
  width: calc(100vw);
  height: calc(100vh);
  text-align: center;
  overflow: hidden;
}

#indexbar {
  width: calc(100vw);
  height: 100px;
  position: absolute;
  z-index: 2;
  text-align: center;
  bottom: 50px;
  opacity: 0;
}

#gopage2 {
  position: absolute;
  font-family: Arial;
  left: calc(100vw / 2 - 50px);
  bottom: 130px;
  background: transparent;
  border: 0;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;  
  padding: 8px 18px;
  text-transform: uppercase;
  box-shadow:0px 0px 20px #fff;
	-moz-box-shadow:0px 0px 20px #fff;
	-webkit-box-shadow:0px 0px 20px #fff;  
}

#black {
  overflow: hidden;
  width: calc(100vw);
  height: calc(100vh);
  background-color: black;
  opacity: 0;
  position: absolute;
  z-index: 999;
  display: none;
}

.button {
    position: absolute;
    width: 30px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    top: 50%;
    margin-top: -25px;
    /* border: 1px solid #fff; */
    opacity: 0.3;
    cursor: pointer;
    color: #fff;
    background: black;
}

#left {
  top: 50%;
  margin-top: -25px;
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 40px;
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/left.png');
  background-size: 100%;
}

#right {
  top: 50%;
  margin-top: -25px;
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 40px;
  left: 100%;
  margin-left: -30px;
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/right.png');
  background-size: 100%;
}

#save {
  position: absolute;
  left: calc(100vw / 2 - 50px);
  bottom: calc(60px + (50vh - 100vw));
  background: transparent;
  border: 0;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;  
  padding: 8px 18px;
  text-transform: uppercase;
  box-shadow:0px 0px 20px #fff;
	-moz-box-shadow:0px 0px 20px #fff;
	-webkit-box-shadow:0px 0px 20px #fff;  
}

#sharetip {
  position: absolute;
  z-index: 320;
  width: calc(100vw);
	height: calc(100vh);
  left: 0;
  top: 0;
	/* display: none;
	justify-content: center;
	flex-direction: column;
	background: rgba(0,0,0,1); */
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/H5-18.png');
  background-size: 100%;
  background-position: 0% 40%;
  text-align: center;
}

#confirmname {
  position: absolute;
  /* right: 70px; */
  left: calc(100vw / 2 - 60px);
  top: 160px;
  background: transparent;
  border: 0;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 28px;
  text-transform: uppercase;  
  cursor: pointer;
  box-shadow:0px 0px 20px #fff;
	-moz-box-shadow:0px 0px 20px #fff;
	-webkit-box-shadow:0px 0px 20px #fff;  
}

#back1 {
  z-index: 100;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/back.png');
  background-size: 100%;
  /* background-position: 0% 40%; */
}

#back5 {
  z-index: 201;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/back.png');
  background-size: 100%;
}

#back7 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/back.png');
  background-size: 100%;
}

#back3 {
  z-index: 160;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;
  background-image: url('https://mf-markdown.obs.cn-south-1.myhuaweicloud.com/light/models/back.png');
  background-size: 100%;
  opacity: 0;
}
</style>

